import { createContext, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import { useDebounce } from 'usehooks-ts'

import { customTokens } from '@/constants/token'
import { FIAT_USD, FIAT_VND, REUSD, REVND } from '@/hooks/use-tokens'
import { TokenInfo } from '@/model/token-info'

import { useFetchExchangeRate } from '../utils/apis/exchange-rate'

export type PersonalBankInformation = {
  bankAccountNumber: string
  bankName: string
  bankAccountName: string
  email: string
  phoneNumber: string
}

type SwapContextType = {
  inputMintAddress: string
  setInputMintAddress: (mintAddress: string) => void
  outputMintAddress: string
  setOutputMintAddress: (mintAddress: string) => void
  setMintAddresses: (s: [string, string]) => void
  switchMintAddresses: () => void
  refreshRate: () => void
  tokenIn: TokenInfo
  tokenOut: TokenInfo
  displayExchangeRate: string
  fee: string
  feeBeforeDiscount?: string
  isLoading: boolean
  inputAmount: string
  outputAmount: string
  setInputAmount: (amount: string) => void
  setBankInformation: (personalBank: PersonalBankInformation) => void
  bankInformation: PersonalBankInformation
  step: number
  changeStep: (step: number) => void
  originalFee?: string
  displayDiscountFee?: number
}

export const FIAT_USD_OBJ: TokenInfo = {
  chainId: 101,
  address: FIAT_USD.address,
  name: FIAT_USD.name,
  decimals: 2,
  symbol: FIAT_USD.symbol,
  logoURI: FIAT_USD.logoURI,
}

export const FIAT_VND_OBJ: TokenInfo = {
  chainId: 101,
  address: FIAT_VND.address,
  name: FIAT_VND.name,
  decimals: 2,
  symbol: FIAT_VND.symbol,
  logoURI: FIAT_VND.logoURI,
}

const initContextState: SwapContextType = {
  inputMintAddress: REUSD,
  setInputMintAddress: () => null,
  outputMintAddress: REVND,
  setOutputMintAddress: () => null,
  switchMintAddresses: () => null,
  setMintAddresses: () => null,
  refreshRate: () => null,
  tokenIn: customTokens.find((token) => token.name === FIAT_USD.name) || FIAT_USD_OBJ,
  tokenOut: customTokens.find((token) => token.name === FIAT_VND.name) || FIAT_VND_OBJ,
  displayExchangeRate: '-',
  isLoading: false,
  inputAmount: '',
  outputAmount: '',
  setInputAmount: () => null,
  bankInformation: {
    bankName: '',
    bankAccountName: '',
    bankAccountNumber: '',
    email: '',
    phoneNumber: '',
  },
  setBankInformation: () => null,
  step: 0,
  changeStep: () => null,
  fee: '0',
}

const SwapContext = createContext<SwapContextType>(initContextState)

export const SwapProvider = ({ children }: HocProps): ReactElement => {
  const [mintAddresses, setMintAddresses] = useState<[string, string]>([
    initContextState.tokenIn.address,
    initContextState.tokenOut.address,
  ])
  const [inputMintAddress, outputMintAddress] = useMemo(() => mintAddresses, [mintAddresses])
  const [inputAmount, setInputAmount] = useState('')
  const [bankInformation, setBankInformation] = useState<PersonalBankInformation>({
    bankName: '',
    bankAccountName: '',
    bankAccountNumber: '',
    email: '',
    phoneNumber: '',
  })
  const [step, changeStep] = useState(0)
  const [lastRefreshedRateAt, setLastRefreshedRateAt] = useState<Date>(new Date())

  const debouncedInputAmount = useDebounce(Number(inputAmount).toFixed(2), 1000)
  const {
    data: dataExchangeRate,
    isLoading,
    refetch: refetchExchangeRate,
  } = useFetchExchangeRate(parseFloat(debouncedInputAmount))

  const tokenIn = useMemo(() => {
    return customTokens.find((token) => token.address === inputMintAddress) || FIAT_USD_OBJ
  }, [inputMintAddress])

  const tokenOut = useMemo(() => {
    return customTokens.find((token) => token.address === outputMintAddress) || FIAT_VND_OBJ
  }, [outputMintAddress])

  const refetchExchangeRateData = () => {
    if (
      debouncedInputAmount === '' ||
      debouncedInputAmount === '0' ||
      !parseFloat(debouncedInputAmount)
    )
      return
    refetchExchangeRate()
  }

  useEffect(() => {
    refetchExchangeRateData()
  }, [debouncedInputAmount])

  const switchMintAddresses = useCallback(() => {
    setMintAddresses([outputMintAddress, inputMintAddress])
  }, [inputMintAddress, outputMintAddress])

  const setInputMintAddress = useCallback(
    (mintAddress: string) => {
      setMintAddresses([mintAddress, outputMintAddress])
    },
    [outputMintAddress],
  )

  const setOutputMintAddress = useCallback(
    (mintAddress: string) => {
      setMintAddresses([inputMintAddress, mintAddress])
    },
    [inputMintAddress],
  )

  const refreshRate = useCallback(() => {
    refetchExchangeRate()
  }, [refetchExchangeRate])

  useEffect(() => {
    refetchExchangeRateData()
    const interval = setInterval(() => {
      setLastRefreshedRateAt(new Date())
    }, 20 * 1000)
    return () => clearInterval(interval)
  }, [lastRefreshedRateAt])

  const fee = dataExchangeRate?.final_fee?.toString() || '0'
  const displayExchangeRate = dataExchangeRate?.display_rate?.toString() || 'init'
  const outputAmount = dataExchangeRate?.expected_amount_out?.toString() || ''
  const originalFee = dataExchangeRate?.original_fee?.toString() || '0'
  const displayDiscountFee = Number(dataExchangeRate?.display_discount_fee)

  const contextValue = useMemo(() => {
    return {
      inputMintAddress,
      setInputMintAddress,
      outputMintAddress,
      setOutputMintAddress,
      switchMintAddresses,
      tokenIn,
      tokenOut,
      displayExchangeRate,
      fee,
      isLoading: isLoading,
      inputAmount,
      outputAmount,
      setInputAmount,
      setBankInformation,
      bankInformation,
      step,
      changeStep,
      refreshRate,
      setMintAddresses,
      originalFee,
      displayDiscountFee,
    }
  }, [
    inputAmount,
    outputAmount,
    setInputAmount,
    inputMintAddress,
    setInputMintAddress,
    outputMintAddress,
    setOutputMintAddress,
    switchMintAddresses,
    tokenIn,
    tokenOut,
    displayExchangeRate,
    fee,
    isLoading,
    setBankInformation,
    bankInformation,
    step,
    changeStep,
    refreshRate,
    setMintAddresses,
    originalFee,
    displayDiscountFee,
  ])

  return <SwapContext.Provider value={contextValue}>{children}</SwapContext.Provider>
}

SwapContext.displayName = 'SwapContext'
export default SwapContext
