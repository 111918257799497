import { isSuccessHttpCode } from '../utils/helpers'
import { parseTransaction } from './response-parsers.ts/transaction'
import { useAuthorizeRequest } from './use-authorize-request'

export const useOngoingTransaction = ({ enabled }: { enabled: boolean }) => {
  return useAuthorizeRequest({
    path: '/transfers/current',
    method: 'GET',
    enabled: enabled,
    transformResponse: (raw_data: any, _header: any, status?: number) => {
      const data = JSON.parse(raw_data)

      if (status && isSuccessHttpCode(status)) {
        return parseTransaction(data)
      }

      return data
    },
  })
}
