import React, { memo, useCallback } from 'react'
import CustomDialog from '@/components/base/CustomDialog'
import { Button, Stack, Typography } from '@mui/material'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import BellImage from './assets/bell.svg'
import { useRouter } from 'next/router'
import { Transaction } from '@/model/transaction'
import useSessionStorage from '@/hooks/useSessionStorage'

type OngoingTransactionModalProps = {
  open: boolean
  setOpen: (value: boolean) => void
  transaction: Transaction
}

const OngoingTransactionModal = (props: OngoingTransactionModalProps) => {
  const { open, setOpen, transaction } = props
  const trans = useTrans()
  const router = useRouter()
  const [hasShownOngoingTransactionModal, setHasShownOngoingTransactionModal] = useSessionStorage(
    'hasShownOngoingTransactionModal',
    false,
  )

  const handleClick = useCallback(() => {
    setHasShownOngoingTransactionModal(true)
    router.push(`/transfers/${transaction?.ref}`)
  }, [transaction, router, setHasShownOngoingTransactionModal])

  const handleClose = useCallback(() => {
    setHasShownOngoingTransactionModal(true)
    setOpen(false)
  }, [setOpen, setHasShownOngoingTransactionModal])

  return (
    <CustomDialog
      handleClose={handleClose}
      isOpened={open && !hasShownOngoingTransactionModal}
      body={
        <Stack alignItems="center" className="text-center p-10">
          <Image src={BellImage} alt="bell-image" width={72} />
          <Typography className="font-ma text-2xl mb-4 pt-10">
            {trans.ongoing_transaction.title}
          </Typography>
          <Typography
            className="text-iw-dark-gray font-interpt-6"
            dangerouslySetInnerHTML={{ __html: trans.ongoing_transaction.msg(transaction?.ref) }}
          />
          <Stack gap={1} className="w-full pt-10">
            <Button variant="contained" onClick={handleClick} data-testid="check-transaction-btn">
              {trans.ongoing_transaction.check_transaction}
            </Button>
            <Button variant="text" className="text-iw-neutral-black" onClick={handleClose}>
              {trans.ongoing_transaction.close}
            </Button>
          </Stack>
        </Stack>
      }
    />
  )
}

export default memo(OngoingTransactionModal)
