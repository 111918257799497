import { Transaction } from '@/model/transaction'
import { round } from 'lodash'

export const parseTransaction = (data: any) => {
  const transaction = {
    id: data.id,
    ref: data.ref,
    amount: data.amount,
    status: data.status,
    rate: data.rate,
    fee: round(data.final_fee, 2).toString(),
    originalFee: data.original_fee,
    statusExplanation: data.status_explanation,
    displayStatus: data.display_status,
    createdAt: new Date(data.created_at),
    outputAmount: data?.expected_output_amount
      ? round(data.expected_output_amount).toString()
      : undefined,
    paymentInformation: data.payment_information && {
      id: data.payment_information.id,
      userId: data.payment_information.user_id,
      externalId: data.payment_information.external_id,
      bankInfo: {
        bankName: data.payment_information.bank_info.bank_name,
        bankAddress: data.payment_information.bank_info.bank_address,
        bankRoutingNumber: data.payment_information.bank_info.bank_routing_number,
        bankAccountNumber: data.payment_information.bank_info.bank_account_number,
      },
      createdAt: new Date(data.payment_information.created_at),
    },
    recipient: data.recipient && {
      id: data.recipient.id,
      userId: data.recipient.user_id,
      bankName: data.recipient?.bank_name,
      bankAccountName: data.recipient?.bank_account_name,
      bankAccountNumber: data.recipient?.bank_account_number,
      email: data.recipient.email,
      phoneNumber: data.recipient.phone_number,
      externalId: data.recipient.external_id,
    },
    deposit: data.deposit && {
      id: data.deposit.id,
      paymentExternalId: data.deposit.payment_external_id,
      fundedExternalId: data.deposit.funded_external_id,
      paymentBankInfo: data.deposit.payment_bank_info,
      destinationTxHash: data.deposit.destination_tx_hash,
      status: data.deposit.status,
      createdAt: new Date(data.deposit.created_at),
      statusExplanation: data.deposit.status_explanation,
      paymentProof: data.deposit.paymentProof,
      uploadedEvidenceAt:
        data.deposit.uploaded_evidence_at && new Date(data.deposit.uploaded_evidence_at),
    },
    swap: data.swap && {
      id: data.swap.id,
      externalId: data.swap.external_id,
      amountOut: data.swap.amount_out,
      status: data.swap.status,
      statusExplanation: data.swap.status_explanation,
      createdAt: new Date(data.swap.created_at),
      updatedAt: new Date(data.swap.updated_at),
    },
    withdrawal: data.swap && {
      id: data.withdrawal.id,
      externalId: data.withdrawal.external_id,
      status: data.withdrawal.status,
      statusExplanation: data.withdrawal.status_explanation,
      createdAt: new Date(data.withdrawal.created_at),
      updatedAt: new Date(data.withdrawal.updated_at),
    },
  } as Transaction

  return transaction
}
