import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'

import OngoingTransactionModal from '@/components/ongoing-transaction-modal'
import { useOngoingTransaction } from '@/hooks/use-ongoing-transaction'
import { useRealtimeTransaction } from '@/hooks/use-realtime-transaction'
import useSessionStorage from '@/hooks/useSessionStorage'
import { Deposit } from '@/model/deposit'
import { Transaction, TransactionOrder } from '@/model/transaction'
import { submitTransaction } from '@/utils/apis/transaction'

type TransactionContextValue = {
  transaction?: Transaction
  isTransactionInDisplayStatuses: (displayStatuses: string[] | string) => boolean
  totalOrders: number
  onrampOrders: TransactionOrder[]
  offrampOrders: TransactionOrder[]
  saveTransaction: () => Promise<Transaction | undefined>
  currentPaymentIndex: number
  handleChangePaymentIndex: (index: number) => void
  currentOrder: TransactionOrder | undefined
  storeTransaction: (transaction: Transaction) => void
  deposit?: Deposit
}

const initContextState: TransactionContextValue = {
  transaction: undefined,
  isTransactionInDisplayStatuses: (_displayStatuses: string[] | string) => false,
  totalOrders: 0,
  onrampOrders: [],
  offrampOrders: [],
  saveTransaction: async () => undefined,
  currentPaymentIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangePaymentIndex: (_index: number) => {},
  currentOrder: undefined,
  storeTransaction: () => undefined,
  deposit: undefined,
}

const TransactionContext = createContext<TransactionContextValue>(initContextState)

type TransactionProviderProps = HocProps & {
  transactionId?: string
}

const TransactionProvider = ({
  transactionId,
  children,
}: TransactionProviderProps): ReactElement => {
  const [unsavedTransaction, setUnsavedTransaction] = useSessionStorage<Transaction | undefined>(
    'draft_transaction',
    undefined,
  )
  const { data: ongoingTransaction } = useOngoingTransaction({ enabled: !transactionId })
  const { data: realtimeTransaction } = useRealtimeTransaction(transactionId)
  const [currentPaymentIndex, setCurrentPaymentIndex] = useState<number>(0)
  const [currentOrder, setCurrentOrder] = useState<TransactionOrder>()
  const [deposit, setCurrentDeposit] = useState<Deposit>()
  const router = useRouter()
  const pathname = usePathname()
  const [openOngoingTransactionModal, setOpenOngoingTransactionModal] = useState<boolean>(
    !pathname?.includes('/transfers/review'),
  )
  const transaction = realtimeTransaction || ongoingTransaction || unsavedTransaction

  useEffect(() => {
    if (!transaction) return
    const order = transaction.transactionOrders?.at(currentPaymentIndex)
    setCurrentDeposit(transaction.deposit)
    setCurrentOrder(order)
    setCurrentDeposit(transaction.deposit)
  }, [transaction, currentPaymentIndex])

  const isTransactionInDisplayStatuses = useCallback(
    (displayStatuses: string[] | string) => {
      if (!transaction) return false

      if (typeof displayStatuses === 'string') return transaction.displayStatus === displayStatuses
      else return displayStatuses.includes(transaction.displayStatus)
    },
    [transaction],
  )

  const totalOrders = useMemo(() => transaction?.transactionOrders?.length || 0, [transaction])

  const onrampOrders = useMemo(
    () =>
      transaction?.transactionOrders?.filter(
        (order: TransactionOrder) => order.orderType === 'onramp',
      ) || [],
    [transaction],
  )

  const offrampOrders = useMemo(
    () =>
      transaction?.transactionOrders?.filter(
        (order: TransactionOrder) => order.orderType === 'offramp',
      ) || [],
    [transaction],
  )

  const saveTransaction = useCallback(async () => {
    if (!unsavedTransaction) return

    const transaction = await submitTransaction({
      amount: unsavedTransaction.amount,
      rate: unsavedTransaction.displayExchangeRate,
      recipientId: unsavedTransaction.recipient?.id,
    })
    setUnsavedTransaction(undefined)
    return transaction
  }, [unsavedTransaction, setUnsavedTransaction])

  const handleChangePaymentIndex = useCallback((index: number) => {
    setCurrentPaymentIndex(index)
  }, [])

  const storeTransaction = useCallback(
    (unsavedTransaction: Transaction) => {
      if (ongoingTransaction) return setOpenOngoingTransactionModal(true)

      setUnsavedTransaction(unsavedTransaction)
      router.push({ pathname: '/bank-detail' })
    },
    [setUnsavedTransaction, ongoingTransaction, setOpenOngoingTransactionModal, router],
  )

  const value = {
    transaction,
    isTransactionInDisplayStatuses,
    totalOrders,
    onrampOrders,
    offrampOrders,
    saveTransaction,
    currentPaymentIndex,
    handleChangePaymentIndex,
    currentOrder,
    storeTransaction,
    deposit,
  }

  return (
    <TransactionContext.Provider value={value}>
      <OngoingTransactionModal
        transaction={ongoingTransaction}
        open={openOngoingTransactionModal && ongoingTransaction}
        setOpen={setOpenOngoingTransactionModal}
      />
      {children}
    </TransactionContext.Provider>
  )
}

export default TransactionProvider

export const useCurrentTransaction = () => useContext(TransactionContext)
