import { Transaction } from '../model/transaction'
import { nextIsRunningCypress } from '@/utils/helpers'
import { parseTransaction } from './response-parsers.ts/transaction'
import { useAuthorizeWebsocket } from './use-authorize-websocket'
import e2eDetailTransaction from '@/root/cypress/fixtures/detail-transactions.json'

export const useRealtimeTransaction = (id_or_ref?: string) => {
  const socketData = useAuthorizeWebsocket<Transaction>({
    channel: 'TransferChannel',
    params: { id_or_ref },
    enabled: !!id_or_ref,
    transformResponse: (data) => {
      return parseTransaction(data)
    },
  })

  if (nextIsRunningCypress && id_or_ref) {
    const data = e2eDetailTransaction[id_or_ref as keyof typeof e2eDetailTransaction]
    // @ts-ignore
    return { data: data as Transaction }
  }

  return socketData
}
