import { useAuthorizeRequest } from '../../hooks/use-authorize-request'

export const useFetchExchangeRate = (inputAmount: number) => {
  return useAuthorizeRequest({
    path: '/exchange_rate',
    method: 'GET',
    params: { amount: inputAmount || 0 },
    allowAnonymous: true,
  })
}
