import FiatUsd from '@/assets/svgs/fiat-usd.svg'
import FiatVnd from '@/assets/svgs/fiat-vnd.svg'

import { TokenInfo } from '../model/token-info'

export type SwapTokenInfo = TokenInfo & {
  nickName?: string
}
export const REUSD = 'reUSD'
export const REVND = 'reVND'
export const FIAT_USD = {
  name: 'USD',
  address: 'xxx',
  symbol: 'USD',
  logoURI: FiatUsd,
}
export const FIAT_VND = {
  name: 'VND',
  address: 'xxx',
  symbol: 'VND',
  logoURI: FiatVnd,
}
