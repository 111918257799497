import { authorizeRequest } from './'

type submitTransactionProps = {
  amount: number | string
  rate: string
  recipientId?: number
}

export const submitTransaction = async (props: submitTransactionProps) => {
  const { amount, rate, recipientId } = props

  return await authorizeRequest({
    path: 'transfers',
    method: 'POST',
    params: {
      recipient_id: recipientId,
      amount: amount,
      rate,
    },
  })
}

export const cancelTransaction = async ({
  id,
  reason,
}: {
  id: number | string
  reason: string
}) => {
  await authorizeRequest({
    path: `deposit/${id}/cancel`,
    params: {
      cancel_reason: reason,
    },
    method: 'POST',
  })
}

export const completeTransaction = async ({ id }: { id: number | string }) => {
  await authorizeRequest({
    path: `transfers/${id}/confirm`,
    method: 'POST',
  })
}

type addOfframpDisputeReasonProps = {
  id: number | string
  proof: File
  reason: string
}

export const addOfframpDisputeReason = async (props: addOfframpDisputeReasonProps) => {
  const { id, proof, reason } = props

  await authorizeRequest({
    path: `transfers/${id}/dispute`,
    method: 'POST',
    params: {
      proof,
      reason,
    },
    multipart: true,
  })
}
